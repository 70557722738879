<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
// import { getModuleMenuList } from "@/api/base.js";
import Header from "_c/Header.vue";
import Footer from "_c/Footer.vue";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  created() {
    this.$store.dispatch("getCompanyMes");
    this.$store.dispatch("getModuleMenuList");
    this.init();
  },
  methods: {
    init() {
      // 检测离上次登录的时间戳
      let token_timestamp = JSON.parse(
        window.localStorage.getItem("token_timestamp") || 0
      );
      let now_timestamp = new Date().getTime();
      if (token_timestamp !== 0) {
        if (now_timestamp - token_timestamp > 24 * 60 * 60 * 1000 * 3) {
          window.localStorage.removeItem("wildanimals_token");
          window.localStorage.removeItem("user_name");
          window.localStorage.removeItem("user_id");
        } else {
          this.$store.commit("CHANGE_SETTING", {
            key: "userName",
            value: window.localStorage.getItem("user_name") || "",
          });
          this.$store.commit("CHANGE_SETTING", {
            key: "userId",
            value: window.localStorage.getItem("user_id") || -1,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import url('./assets/font/iconfont.css');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
