import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import config from '@/config'
Vue.prototype.$config = config

Vue.prototype.$store = store

Vue.config.productionTip = false


// 样式
import '@/styles/base.css'
import '@/styles/main.css'
import '@/styles/element.css'

// 混入
import mixin from '@/mixins/mixin.js'
Vue.mixin(mixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
