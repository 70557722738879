<template>
  <div class="policies" style="background: #f1f1f1;">
    <div class="type-title">
      <Title
        text-color="#000"
        bg-color="#f1f1f1"
        line-color="#007cf9"
        btn-color="#fff"
        zh-text="Policies and Regulations"
        cn-text="政策法规"
        @toMore="handleMore"
      />
    </div>
    <div class="policies-pc">
      <div class="type-main">
        <template v-for="(l, i) in list">
          <div class="main-item" :key="l.id" v-if="i < 3">
            <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
            <div class="item-box">
              <div class="item-name scroll-hidden">{{ l.name || "--" }}</div>
              <span @click="toDetail(l)">查看详情</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="policies-mobile">
      <div class="type-main">
        <template v-if="list.length">
          <div class="main-item">
            <img
              :src="
                baseUrl +
                (list[currentIndex].img && list[currentIndex].img.file_path)
              "
              alt=""
            />
            <div class="item-box">
              <div class="item-name scroll-hidden">
                {{ list[currentIndex].name || "--" }}
              </div>
              <span @click="toDetail(list[currentIndex])">查看详情</span>
            </div>
          </div>
          <i class="el-icon-arrow-left" @click="changeIndex(-1)"></i>
          <i class="el-icon-arrow-right" @click="changeIndex(1)"></i>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";

export default {
  name: "Policies",
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    // 查看更多
    handleMore() {
      let item = this.list[0];
      if (item) {
        let menuList = this.moduleMenuList.find(
          (el) => el.module_code === item.module_code
        );
        let firstItem = menuList.typelist[0];
        if (firstItem) {
          let name = this.matchName(firstItem);
          this.$router.push({
            name,
            query: {
              module_code: firstItem.module_code,
              id: firstItem.id,
            },
          });
        }
      }
    },
    toDetail(item) {
      // page_customized
      const { id, module_code, typeid } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";
      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: typeid,
          cid: id,
        },
      });
    },
    changeIndex(num) {
      if (num > 0) {
        // 后
        if (this.currentIndex === this.list.length - 1) {
          this.currentIndex = -1;
        }
      } else {
        // 前
        if (this.currentIndex === 0) {
          this.currentIndex = this.list.length;
        }
      }
      this.currentIndex += num;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .policies-mobile {
    display: none;
  }

  .type-main {
    width: 1200px;
    min-height: 450px;
    margin: 0 auto;
    // padding: 0 6%;
    display: flex;
    justify-content: space-between;
  }
  .main-item {
    width: 30%;
    height: 450px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
    .item-box {
      width: 100%;
      position: absolute;
      bottom: 0;
      background: rgba(49, 45, 42, 0.75);
      color: #fff;
      padding: 18px;
      .item-name {
        font-size: 12px;
        height: 26px;
        line-height: 26px;
        white-space: nowrap;
        overflow-x: auto;
        margin-bottom: 8px;
      }
      span {
        padding: 4px 14px;
        background: #007cf9;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .policies-pc {
    display: none;
  }
  .type-main {
    width: 100%;
    min-height: 450px;
    margin: 0 auto;
    padding: 0 6%;
    position: relative;
  }
  .main-item {
    width: 100%;
    height: 450px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
    .item-box {
      width: 100%;
      position: absolute;
      bottom: 0;
      background: rgba(49, 45, 42, 0.75);
      color: #fff;
      padding: 18px;
      .item-name {
        font-size: 12px;
        height: 26px;
        line-height: 26px;
        white-space: nowrap;
        overflow-x: auto;
        margin-bottom: 8px;
      }
      span {
        padding: 4px 14px;
        background: #007cf9;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .el-icon-arrow-left {
    position: absolute;
    top: 50%;
    left: 0;
    padding: 10px;
    transform: translateY(-50%);
  }
  .el-icon-arrow-right {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 10px;
    transform: translateY(-50%);
  }
}
.policies {
  background: #fff;
  padding-bottom: 4%;
}
</style>