import request from '@/utils/request'

// 获取公司信息
export const getCompanyMes = () => {
    return request({
        url: '/api/Information/GetCompanyMes',
        method: 'get'
    })
}

// 获取菜单栏
export const getModuleMenuList = () => {
    return request({
        url: '/api/Module/GetModuleMenuList',
        method: 'get'
    })
}

// 获取轮播图
export const getBannerList = () => {
    return request({
        url: '/api/Information/GetBannerList',
        method: 'get'
    })
}

// 获取首页列表
export const getModuleBaseIndex = () => {
    return request({
        url: '/api/Module/GetModuleBaseIndex',
        method: 'get'
    })
}

// 登录
export const login = (params) => {
    return request({
        url: '/api/UserBase/Login',
        method: 'post',
        data: params
    })
}

// 注册
export const register = (params) => {
    return request({
        url: '/api/UserBase/Register',
        method: 'post',
        data: params
    })
}

// 发送验证码
export const sendIdentificationCode = (params) => {
    return request({
        url: '/api/UserBase/SendIdentificationCode',
        method: 'post',
        data: params
    })
}

// 获取模块下菜单列表
export const getModuleTypeList = (params) => {
    return request({
        url: '/api/Module/GetModuleTypeList',
        method: 'get',
        params
    })
}

// 获取单页面内容
export const getModuleTypeMes = (params) => {
    return request({
        url: '/api/Module/GetModuleTypeMes',
        method: 'get',
        params
    })
}

// 提交信息
export const addInformation = (params) => {
    return request({
        url: '/api/Information/AddInformation',
        method: 'post',
        data: params
    })
}

// 获取模块详情
export const getModuleBaseMes = (params) => {
    return request({
        url: '/api/Module/GetModuleBaseMes',
        method: 'get',
        params
    })
}

// 搜索
export const getModuleBaseSearch = (params) => {
    return request({
        url: '/api/Module/GetModuleBaseSearch',
        method: 'get',
        params
    })
}

// 获取列表详情
export const getModuleBaseList = (params) => {
    return request({
        url: '/api/Module/GetModuleBaseList',
        method: 'get',
        params
    })
}

// 获取详情（课程）
export const getCourseMes = (params) => {
    return request({
        url: '/api/Course/GetCourseMes',
        method: 'get',
        params
    })
}

// 提交评论（课程）
export const addCourseComment = (params) => {
    return request({
        url: '/api/Course/AddCourseComment',
        method: 'post',
        data: params
    })
}

// 获取评论（课程）
export const getCourseCommentList = (params) => {
    return request({
        url: '/api/Course/GetCourseCommentList',
        method: 'get',
        params
    })
}

// 点赞（课程）
export const likeCourseComment = (params) => {
    return request({
        url: '/api/Course/LikeCourseComment',
        method: 'get',
        params
    })
}
