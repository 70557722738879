import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import config from '@/config'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: config.baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
    headers: {
        "MerchantId": "8e5fc5be551344419cf485eecdd49ppd",
    }
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (config.url.indexOf('Course') > -1) {
            config.headers['authorization'] = window.localStorage.getItem('wildanimals_token')
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        // Do something with response data
        const { data } = response
        return data
    },
    error => {
        return Promise.reject(error)
    }
)

export default service