<template>
  <div class="trends">
    <div class="type-title">
      <Title
        text-color="#fff"
        bg-color="#007cf9"
        line-color="#fff"
        btn-color="#007cf9"
        zh-text="Trends in Crime Prevention"
        cn-text="行业动态"
        @toMore="handleMore"
      />
    </div>
    <div class="trends-pc">
      <div class="type-main">
        <el-carousel
          :interval="4000"
          type="card"
          height="384px"
          indicator-position="none"
        >
          <el-carousel-item v-for="l in list" :key="l.id">
            <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
            <span class="btn" @click="toDetail(l)">点击查看</span>
            <div class="item-name scroll-hidden">{{ l.name || "--" }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="trends-mobile">
      <div class="type-main">
        <el-carousel :interval="4000" direction="vertical" height="352px">
          <el-carousel-item v-for="l in list" :key="l.id">
            <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
            <span class="btn" @click="toDetail(l)">点击查看</span>
            <div class="item-name scroll-hidden">{{ l.name || "--" }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";

export default {
  name: "Trends",
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    // 查看更多
    handleMore() {
      let item = this.list[0];
      if (item) {
        let menuList = this.moduleMenuList.find(
          (el) => el.module_code === item.module_code
        );
        let firstItem = menuList.typelist[0];
        if (firstItem) {
          let name = this.matchName(firstItem);
          this.$router.push({
            name,
            query: {
              module_code: firstItem.module_code,
              id: firstItem.id,
            },
          });
        }
      }
    },
    toDetail(item) {
      // page_customized
      const { id, module_code, typeid } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";
      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: typeid,
          cid: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .trends-mobile {
    display: none;
  }

  .type-main {
    width: 1200px;
    min-height: 384px;
    margin: 0 auto;
    // padding: 0 6%;
    /deep/ .el-carousel {
      width: 100%;
      height: 100%;

      .el-carousel__item {
        // text-align: center;
        // position: relative;
        img {
          // width: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .btn {
          position: absolute;
          left: 50%;
          bottom: 24%;
          transform: translateX(-50%);
          background: #007cf9;
          padding: 10px 26px;
          color: #fff;
          font-size: 12px;
        }
        .item-name {
          width: 100%;
          position: absolute;
          bottom: 10%;
          padding: 0 20%;
          text-align: center;
          white-space: nowrap;
          color: #fff;
          text-shadow: 1px 1px 1px #000;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .trends-pc {
    display: none;
  }

  .type-main {
    width: 100%;
    min-height: 352px;
    margin: 0 auto;
    padding: 0 6%;
    /deep/ .el-carousel {
      width: 100%;
      height: 100%;

      .el-carousel__item {
        // text-align: center;
        // position: relative;
        img {
          // width: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .btn {
          position: absolute;
          left: 50%;
          bottom: 24%;
          transform: translateX(-50%);
          background: #007cf9;
          padding: 10px 26px;
          color: #fff;
          font-size: 12px;
        }
        .item-name {
          width: 100%;
          position: absolute;
          bottom: 10%;
          padding: 0 6%;
          text-align: center;
          white-space: nowrap;
          color: #fff;
          text-shadow: 1px 1px 1px #000;
          overflow-x: auto;
        }
      }
    }
  }
}
.trends {
  background: #007cf9;
  padding-bottom: 4%;
}
</style>