<template>
  <div class="industry" style="background: #f1f1f1;">
    <div class="type-title">
      <Title
        text-color="#000"
        bg-color="#f1f1f1"
        line-color="#007cf9"
        btn-color="#fff"
        zh-text="Lectures and Webinars"
        cn-text="交流研讨"
        @toMore="handleMore"
      />
    </div>
    <div class="type-main">
      <div class="main-item-list">
        <template v-for="(l,i) in list">
          <div class="main-item" :key="l.id" v-if="i < 3">
            <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
            <div class="item-box">
              <div class="item-name list-scroll">{{ l.name || "--" }}</div>
              <div class="item-time">{{ l.time || "--" }}</div>
              <div class="item-btn" @click="toDetail(l)">
                查看<br />详情
                <!-- <template v-if="l.page_customized === 'DiscussTheIndustry'">
                  注册<br />报名
                </template>
                <template v-else> 查看<br />详情 </template> -->
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";
import $ from "jquery";

export default {
  name: "Industry",
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        if (val.length) {
          // clearInterval(this.timeId);
          // this.timeId = null;
          // this.$nextTick(() => {
          //   this.startScroll();
          // });
        }
      },
    },
  },
  data() {
    return {
      timeId: null,
    };
  },
  mounted() {},
  methods: {
    // 查看更多
    handleMore() {
      console.log("查看更多");
      let item = this.list[0];
      if (item) {
        let menuList = this.moduleMenuList.find(
          (el) => el.module_code === item.module_code
        );
        let firstItem = menuList.typelist.find(el=>el.page_customized === 'DiscussTheIndustry')
        if (firstItem) {
          let name = this.matchName(firstItem);
          this.$router.push({
            name,
            query: {
              module_code: firstItem.module_code,
              id: firstItem.id,
            },
          });
        }
      }
    },
    toDetail(item) {
      // page_customized
      const { id, module_code, typeid } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";

      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: typeid,
          cid: id,
        },
      });
    },
    startScroll() {
      const mainBox = $(".type-main");
      const listBox = $(".main-item-list");
      const mainBoxWidth = mainBox.width();
      const listBoxWidth = listBox.outerWidth(true);
      if (listBoxWidth > mainBoxWidth) {
        this.timeId = setInterval(() => {
          let left = listBox.offset().left;
          if (Math.abs(left) >= listBoxWidth - mainBoxWidth) {
            left = 0;
          }
          left -= 1;
          listBox.offset({ left });
        }, 50);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .type-main {
    width: 1200px;
  }
  .industry {
    background: #fff;
    padding-bottom: 30px;

    .type-title {
    }
    .type-main {
      min-height: 290px;
      margin: 0 auto;
      overflow: hidden;
      // overflow-x: auto;
      position: relative;

      .main-item-list {
        width: 100%;
        display: flex;
        justify-content:space-between;
        position: absolute;
        left: 0;
      }
      .main-item {
        width: 390px;
        height: 290px;
        position: relative;
        // margin-right: 22px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .item-box {
          position: absolute;
          left: 12%;
          right: 12%;
          top: 14%;
          bottom: 14%;
          background: rgba(255, 255, 255, 0.62);
          padding: 28px 24px;
          display: flex;
          flex-direction: column;
          .item-name {
            flex: 1;
            font-size: 24px;
            font-weight: 700;
            overflow: auto;
          }
          .item-time {
            height: 20px;
            font-size: 14px;
            font-weight: 700;
          }
          .item-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 15px 20px;
            background: #007cf9;
            font-weight: 700;
            color: #fff;
            border-radius: 50%;
            transform: translate(40%, 40%);
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .type-main {
    width: 100%;
  }
  .industry {
    background: #fff;
    padding-bottom: 30px;

    .type-title {
    }
    .type-main {
      min-height: 290px;
      margin: 0 auto;
      overflow: hidden;
      // overflow-x: auto;
      position: relative;

      .main-item-list {
        width: 100%;
        display: flex;
        position: absolute;
        left: 0;
        justify-content: center;
      }
      .main-item {
        // width: 390px;
        width: 100%;
        height: 290px;
        position: relative;
        margin-right: 22px;
        display:none;

        &:first-child{
          display:block;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .item-box {
          position: absolute;
          left: 12%;
          right: 12%;
          top: 14%;
          bottom: 14%;
          background: rgba(255, 255, 255, 0.62);
          padding: 28px 24px;
          display: flex;
          flex-direction: column;
          .item-name {
            flex: 1;
            font-size: 24px;
            font-weight: 700;
            overflow: auto;
          }
          .item-time {
            height: 20px;
            font-size: 14px;
            font-weight: 700;
          }
          .item-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 15px 20px;
            background: #007cf9;
            font-weight: 700;
            color: #fff;
            border-radius: 50%;
            transform: translate(40%, 40%);
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>