import Vue from 'vue'
import Vuex from 'vuex'

import { getCompanyMes, getModuleMenuList } from "@/api/base.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    company: '',
    name: '',
    copyright: '',
    icpcode: '',
    logoUrl: '',
    moduleMenuList: [],
    moduleName: '',
    userName: window.localStorage.getItem('user_name'),
    userId: window.localStorage.getItem('user_id')
  },
  mutations: {
    CHANGE_SETTING: (state, { key, value }) => {
      state[key] = value
    }
  },
  actions: {
    getCompanyMes({ commit }) {
      getCompanyMes().then((res) => {
        if (res.code === 200) {
          const { data } = res
          commit('CHANGE_SETTING', { key: 'company', value: data.company })
          commit('CHANGE_SETTING', { key: 'name', value: data.name })
          commit('CHANGE_SETTING', { key: 'copyright', value: data.copyright })
          commit('CHANGE_SETTING', { key: 'icpcode', value: data.icpcode })
          commit('CHANGE_SETTING', { key: 'logoUrl', value: data.logo && data.logo.file_path })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getModuleMenuList({ commit }) {
      getModuleMenuList().then((res) => {
        if (res.code === 200) {
          const { data } = res
          commit('CHANGE_SETTING', { key: 'moduleMenuList', value: data })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  getters: {
    company(state) {
      return state.company
    },
    name(state) {
      return state.name
    },
    copyright(state) {
      return state.copyright
    },
    icpcode(state) {
      return state.icpcode
    },
    logoUrl(state) {
      return state.logoUrl
    },
    moduleMenuList(state) {
      return state.moduleMenuList
    },
    moduleName(state) {
      return state.moduleName
    },
    userName(state) {
      return state.userName
    },
    userId(state) {
      return state.userId
    }
  },
  modules: {
    // number: {
    //   namespaced: true,
    //   state: {
    //     aa: 123
    //   },
    //   mutations: {
    //     SET_AA: (state) => {
    //       state.aa += 1
    //     }
    //   }
    // }
  }
})
