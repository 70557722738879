<template>
  <div class="header">
    <div class="header-pc">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="4" class="logo-box fcc"
          ><img :src="baseUrl + logoUrl" alt="logo"
        /></el-col>
        <el-col :span="14" class="menu-box fcc">
          <span
            class="main-menu"
            :class="{ active: isActiveMenu('home') }"
            @click="handleCommand('home')"
            >首页</span
          >
          <!-- <span
            class="main-menu"
            v-for="item in moduleMenuList"
            :key="item.id"
            >{{ item.module_name }}</span
          > -->
          <el-dropdown
            v-for="item in moduleMenuList"
            :key="item.id"
            placement="bottom"
            @command="handleCommand"
          >
            <span
              class="main-menu"
              :class="{ active: isActiveMenu(item.module_code) }"
            >
              {{ item.module_name
              }}<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="cItem in item.typelist"
                :key="cItem.id"
                :command="item.module_code + '-' + cItem.id"
                >{{ cItem.type_name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2" class="search-box fcc">
          <span @click="toSearch"><i class="el-icon-search"></i>搜索</span>
        </el-col>
        <el-col :span="4" class="user-box fcc">
          <template v-if="!userName">
            <span @click="handleCommand('login')"
              ><i class="el-icon-user"></i>登录</span
            >
            |
            <span @click="handleCommand('register')">注册</span>
          </template>
          <template v-else>
            <span class="has-login scroll-hidden"
              ><i class="el-icon-user"></i>{{ userName }}</span
            >
          </template>
        </el-col>
      </el-row>
    </div>
    <div class="header-mobile">
      <el-row :gutter="20" type="flex" justify="center" align="middle">
        <el-col :span="5" class="logo-box fcc"
          ><img :src="baseUrl + logoUrl" alt="logo"
        /></el-col>
        <el-col :span="15" class="search-box fcc">
          <span @click="toSearch"><i class="el-icon-search"></i>搜索</span>
        </el-col>
        <el-col :span="4" class="menu-box fcc">
          <i class="el-icon-menu" @click="drawer = true"></i>
        </el-col>
      </el-row>

      <el-drawer
        title="菜单栏"
        :with-header="false"
        :visible.sync="drawer"
        direction="ltr"
        custom-class="mobile-menu"
        :show-close="false"
        :append-to-body="true"
      >
        <el-menu
          :unique-opened="true"
          :default-active.sync="defaultActive"
          @select="handleSelect"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="home">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu
            v-for="item in moduleMenuList"
            :key="item.id"
            :index="item.module_code"
          >
            <template slot="title">
              <span>{{ item.module_name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="cItem in item.typelist"
                :key="cItem.id"
                :index="item.module_code + '-' + cItem.id"
                >{{ cItem.type_name }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <template v-if="!userName">
            <el-menu-item index="login">
              <span slot="title">登录</span>
            </el-menu-item>
            <el-menu-item index="register">
              <span slot="title">注册</span>
            </el-menu-item>
          </template>
          <template v-else>
            <div class="has-login">
              <i class="el-icon-user"></i>{{ userName }}
            </div>
          </template>
        </el-menu>
      </el-drawer>
    </div>
    <img
      v-if="bannerUrl"
      class="banner-img"
      :src="baseUrl + bannerUrl"
      alt=""
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      drawer: false, // 移动端左侧菜单栏
      defaultActive: "", // 移动端--当前激活菜单的 index
      cId: "", // 详情id
      bannerUrl: "",
    };
  },
  computed: {
    ...mapGetters(["logoUrl", "userName"]),
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        const { name, query } = val;
        console.log(val);
        if (name === "Home") {
          this.defaultActive = "home";
        } else if (name === "Search") {
          this.defaultActive = "search";
        } else if (name === "Login") {
          this.defaultActive = "login";
        } else if (name === "Register") {
          this.defaultActive = "register";
        } else if (name === "Error") {
          this.defaultActive = "";
          this.bannerUrl = "";
        } else {
          // 模块
          if (query.module_code && query.id) {
            this.defaultActive = query.module_code + "-" + query.id;
          }
        }
        this.cId = query.cid;
      },
    },
    defaultActive(val) {
      if (val.split("-").length > 1) {
        const modulecode = val.split("-")[0];
        const pId = Number(val.split("-")[1]);

        if (this.moduleMenuList.length) {
          let item = this.moduleMenuList.find(
            (el) => el.module_code === modulecode
          );
          this.bannerUrl = item.module_img && item.module_img.file_path;
          if(!this.cId){
            let cItem = item.typelist.find((el) => el.id === pId);
            this.goModuleList(cItem, item.module_name);
          }
        }
      } else {
        if (val === "register") {
          this.bannerUrl = require("@/assets/register.png");
        } else {
          this.bannerUrl = "";
        }
      }
    },
    moduleMenuList: {
      deep: true,
      handler(val) {
        // 只执行一次
        if (this.defaultActive === "register") {
          this.bannerUrl = require("@/assets/register.png");
        } else {
          let item = val.find(
            (el) => el.module_code === this.defaultActive.split("-")[0]
          );
          this.bannerUrl = item && item.module_img && item.module_img.file_path;
        }
      },
    },
  },

  created() {},
  mounted() {},
  methods: {
    // pc端菜单栏事件
    handleCommand(command) {
      console.log("command-------------", command);
      this.cId = "";
      if (command === "home") {
        // 首页
        this.$router.push({ name: "Home" });
      } else if (command === "login") {
        // 登录
        this.$router.push({ name: "Login" });
      } else if (command === "register") {
        // 注册
        this.$router.push({ name: "Register" });
      }
      // if (this.defaultActive === command) {
      // } else {
      //   this.defaultActive = command;
      // }
      if (this.defaultActive === command) {
        const modulecode = command.split("-")[0];
        const pId = Number(command.split("-")[1]);
        let item = this.moduleMenuList.find(
          (el) => el.module_code === modulecode
        );
        let cItem = item.typelist.find((el) => el.id === pId);
        this.goModuleList(cItem, item.module_name);
      } else {
        this.defaultActive = command;
      }
    },
    isActiveMenu(name) {
      // console.log(this.defaultActive.split("-")[0]);
      return this.defaultActive.split("-")[0] === name;
    },

    // 移动端菜单栏事件
    handleSelect(index) {
      this.handleCommand(index);
      this.drawer = false;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    // 搜索
    toSearch() {
      this.$prompt("请输入搜索内容", "搜索", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "search-message-box",
      })
        .then((res) => {
          if (res.value === null) {
            this.$message({
              type: "warning",
              customClass: "message-boxt-class",
              message: "请输入内容",
            });
            return;
          }
          this.$router.push({
            name: "Search",
            query: { keyword: res.value },
          });
        })
        .catch(() => {
          console.log("cancel");
        });
    },

    goModuleList(item, moduleName) {
      let name = this.matchName(item);

      this.$store.commit("CHANGE_SETTING", {
        key: "moduleName",
        value: moduleName,
      });

      this.$router.push({
        name,
        query: { module_code: item.module_code, id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #0981e4;

.el-row,
.el-col {
  height: 100%;
}
.header-pc .el-row {
  width: 1200px;
  margin: 0 auto !important;
}

.fcc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-img {
  width: 100%;
  // object-fit: cover;
  vertical-align: middle;
}

@media only screen and (min-width: 769px) {
  .header-pc {
    width: 100%;
    height: 120px;
    background: $mainColor;
    padding: 0 4%;
    color: #fff;

    .logo-box {
      img {
        width: 120px;
        height: auto;
        object-fit: cover;
      }
    }

    .menu-box {
      .main-menu {
        padding: 10px 10px;
        margin: 6px 4px;
        font-size: 16px;
        cursor: pointer;

        &.active {
          border-bottom: 1px solid #fff;
        }
      }

      & /deep/ .el-dropdown {
        &::before {
          content: "";
          border-left: 1px solid #fff;
        }
        .main-menu {
          color: #fff;
          padding: 10px 10px;
          margin: 6px 4px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }

    .search-box {
      span {
        cursor: pointer;
      }
      i {
        margin-right: 4px;
      }
    }

    .user-box {
      span {
        margin: 0 8px;
        cursor: pointer;
      }
      i {
        border: 1px solid#fff;
        border-radius: 50%;
        margin-right: 4px;
      }

      .has-login {
        cursor: default;
        white-space: nowrap;
        overflow-y: auto;
      }
    }
  }
  .header-mobile {
    display: none;
  }
  .banner-img {
    // max-height: 250px;
    min-height: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .header-pc {
    display: none;
  }
  .header-mobile {
    width: 100%;
    height: 60px;
    background: $mainColor;
    padding: 0 10px;
    color: #fff;

    .logo-box {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .search-box {
      span {
      }
      i {
        margin-right: 4px;
      }
    }

    .menu-box {
      i {
        font-size: 24px;
      }
    }
  }
  .banner-img {
    // height: 70px;
  }
}
</style>