<template>
  <div class="crimes">
    <div class="type-title">
      <Title
        text-color="#000"
        bg-color="#f1f1f1"
        line-color="#007cf9"
        btn-color="#fff"
        zh-text="Trends in Combating Wildlife Crimes"
        cn-text="案件动态"
        @toMore="handleMore"
      />
    </div>
    <div class="crimes-pc">
      <div class="type-main">
        <div class="type-main-left" @click="toDetail()">
          <img :src="baseUrl + imgUrl" alt="" />
        </div>
        <div class="type-main-right">
          <template v-for="(l, i) in list">
            <div
              class="right-item"
              :class="{ active: currentIndex === i }"
              :key="l.id"
              v-if="i < 5"
              @click="changeIndex(i)"
            >
              {{ l.name }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="crimes-mobile">
      <div class="type-main">
        <template v-if="list.length > 0">
          <div class="main-item">
            <img
              :src="
                baseUrl +
                (list[currentIndex].img && list[currentIndex].img.file_path)
              "
              alt=""
            />
            <div class="item-name list-scroll" @click="toDetail()">
              {{ list[currentIndex].name || "--" }}
            </div>
            <div class="item-btns">
              <template v-for="i in list.length">
                <span
                  :key="i"
                  v-if="i < 6"
                  :class="{ active: currentIndex === i - 1 }"
                  @click="currentIndex = i - 1"
                  >{{ i }}</span
                >
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";

export default {
  name: "Crimes",
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        if (val.length > 1) {
          this.autoSwitchIndex();
        }
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
      timeId:null
    };
  },
  computed: {
    imgUrl() {
      if (this.list.length > 0) {
        return this.list[this.currentIndex].img.file_path;
      } else {
        return "";
      }
    },
  },
  methods: {
    // 查看更多
    handleMore() {
      let item = this.list[0];
      if (item) {
        let menuList = this.moduleMenuList.find(
          (el) => el.module_code === item.module_code
        );
        let firstItem = menuList.typelist[0];
        if (firstItem) {
          let name = this.matchName(firstItem);
          this.$router.push({
            name,
            query: {
              module_code: firstItem.module_code,
              id: firstItem.id,
            },
          });
        }
      }
    },
    toDetail() {
      let item = this.list[this.currentIndex];
      // page_customized
      const { id, module_code, typeid } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";

      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: typeid,
          cid: id,
        },
      });
    },
    changeIndex(index){
      this.currentIndex = index
      this.toDetail()
    },
    autoSwitchIndex(){
      clearInterval(this.timeId);
      this.timeId = null;
      this.timeId = setInterval(() => {
        if (this.currentIndex === this.list.length - 1) {
          this.currentIndex = -1;
        }
        this.currentIndex += 1;
      }, 5000);
    }
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .crimes-mobile {
    display: none;
  }

  .type-main {
    width: 1200px;
    min-height: 460px;
    margin: 0 auto;
    // padding: 0 6%;
    display: flex;

    .type-main-left {
      width: 60%;
      height: 460px;

      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
    .type-main-right {
      width: 40%;

      .right-item {
        height: 20%;
        background: #fff;
        padding: 0 50px 0 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;
        &:last-child {
          border-bottom: 0 none;
        }
        position: relative;

        &.active::before {
          position: absolute;
          left: -40px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 20px solid transparent;
          border-right: 20px solid #fff;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .crimes-pc {
    display: none;
  }
  .type-main {
    width: 100%;
    min-height: 230px;
    margin: 0 auto;
    // padding: 0 6%;
  }
  .main-item {
    width: 100%;
    background: #fff;
    color: #525252;
    position: relative;

    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      vertical-align: middle;
    }
    .item-name {
      font-size: 14px;
      font-weight: 700;
      position: absolute;
      top: 10%;
      bottom: 10%;
      left: 8%;
      right: 8%;
      background: rgba(255, 255, 255, 0.6);
      padding: 10px;
    }

    .item-btns {
      width: 100%;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      display: flex;
      justify-content: center;

      span {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        margin-right: 6px;
        background: #fff;
        color: #2c3e50;
        &.active {
          background: #007cf9;
          color: #fff;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.crimes {
  background: #f1f1f1;
  padding-bottom: 30px;
}
</style>