<template>
  <div class="course">
    <div class="type-title">
      <Title
        zh-text="Law Enforcement Capacity Building Course"
        cn-text="执法课程"
        @toMore="handleMore"
      />
    </div>
    <div class="course-pc">
      <div class="type-main">
        <template v-for="(l, i) in list">
          <div class="main-item" :key="l.id" v-if="i < 3">
            <img :src="baseUrl + (l.img && l.img.file_path)" alt="" />
            <div class="item-name scroll-hidden">{{ l.name || "--" }}</div>
            <div class="item-mes list-scroll">
              {{ l.mes }}
            </div>
            <div class="item-btns">
              <span>{{ l.click_num }}次点击</span>
              <span @click="toDetail(l)">学习课程</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="course-mobile">
      <div class="type-main">
        <template v-if="list.length > 0">
          <div class="main-item">
            <img
              :src="
                baseUrl +
                (list[currentIndex].img && list[currentIndex].img.file_path)
              "
              alt=""
            />
            <div class="item-name scroll-hidden">
              {{ list[currentIndex].name || "--" }}
            </div>
            <div class="item-mes list-scroll">
              {{ list[currentIndex].mes }}
            </div>
            <div class="item-btns">
              <span>{{ list[currentIndex].click_num }}次点击</span>
              <span @click="toDetail(list[currentIndex])">学习课程</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";
export default {
  name: "Course",
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        if (val.length > 1) {
          this.addScroll();
        }
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
      timeId: null,
    };
  },
  methods: {
    // 查看更多
    handleMore() {
      console.log("查看更多");
      let item = this.list[0];
      if (item) {
        let menuList = this.moduleMenuList.find(
          (el) => el.module_code === item.module_code
        );
        let firstItem = menuList.typelist[0];
        if (firstItem) {
          let name = this.matchName(firstItem);
          this.$router.push({
            name,
            query: {
              module_code: firstItem.module_code,
              id: firstItem.id,
            },
          });
        }
      }
    },

    toDetail(item) {
      // page_customized
      const { id, module_code, typeid } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";
      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: typeid,
          cid: id,
        },
      });
    },
    addScroll() {
      clearInterval(this.timeId);
      this.timeId = null;
      this.timeId = setInterval(() => {
        if (this.currentIndex === this.list.length - 1) {
          this.currentIndex = -1;
        }
        this.currentIndex += 1;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .course-mobile {
    display: none;
  }

  .type-main {
    width: 1200px;
    min-height: 422px;
    margin: 0 auto;
    // padding: 0 6%;
    display: flex;
    justify-content: space-between;
  }
  .main-item {
    width: 30%;
    padding: 16px;
    background: #fff;
    color: #525252;

    img {
      width: 100%;
      height: 256px;
      object-fit: contain;
    }
    .item-name {
      font-size: 14px;
      height: 26px;
      line-height: 26px;
      white-space: nowrap;
      overflow-x: auto;
      font-weight: 700;
    }
    .item-mes {
      font-size: 12px;
      height: 60px;
      line-height: 1.5;
      overflow-y: auto;
    }
    .item-btns {
      font-size: 14px;
      height: 36px;
      line-height: 36px;
      display: flex;
      margin-top: 8px;
      span {
        width: 50%;
        text-align: center;
        background: #f2b624;

        &:first-child {
          color: #fff;
          background: #53b1fa;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .course-pc {
    display: none;
  }
  .type-main {
    width: 100%;
    min-height: 396px;
    margin: 0 auto;
    padding: 0 6%;
  }
  .main-item {
    width: 100%;
    padding: 16px;
    background: #fff;
    color: #525252;

    img {
      width: 100%;
      height: 230px;
      object-fit: contain;
    }
    .item-name {
      font-size: 14px;
      height: 26px;
      line-height: 26px;
      white-space: nowrap;
      overflow-x: auto;
      font-weight: 700;
    }
    .item-mes {
      font-size: 12px;
      height: 60px;
      line-height: 1.5;
      overflow-y: auto;
    }
    .item-btns {
      font-size: 14px;
      height: 36px;
      line-height: 36px;
      display: flex;
      margin-top: 8px;
      span {
        width: 50%;
        text-align: center;
        background: #f2b624;

        &:first-child {
          color: #fff;
          background: #53b1fa;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}
.course {
  background: #1b92ee;
  padding-bottom: 30px;
}
</style>