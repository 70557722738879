<template>
  <div class="home">
    <Carousel />
    <Crimes :list="filterMode('LawEnforcementAndJudicial')" />
    <Course :list="filterMode('LawEnforcementTools')" />
    <Policies :list="filterMode('PoliciesAndRegulations')" />
    <Trends :list="filterMode('CrimePrevention')" />
    <Industry :list="filterMode('CrimePrevention', true)" />
  </div>
</template>

<script>
import { getModuleBaseIndex } from "@/api/base.js";

import Carousel from "_c/home/Carousel.vue";
import Crimes from "_c/home/Crimes";
import Course from "_c/home/Course";
import Policies from "_c/home/Policies";
import Trends from "_c/home/Trends";
import Industry from "_c/home/Industry";

export default {
  name: "Home",
  components: {
    Carousel,
    Crimes,
    Course,
    Policies,
    Trends,
    Industry,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getModuleBaseIndex();
  },
  methods: {
    getModuleBaseIndex() {
      getModuleBaseIndex()
        .then((res) => {
          const { data } = res;
          console.log("data", data);
          this.list = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterMode(module_code, extra) {
      if (this.list) {
        let item = this.list.find((el) => el.module_code === module_code);
        if (item && module_code === "CrimePrevention") {
          if (extra) {
            return item.list.filter(
              (el) => el.page_customized === "DiscussTheIndustry"
            );
          } else {
            return item.list.filter(
              (el) => el.page_customized !== "DiscussTheIndustry"
            );
          }
        }
        if(item && module_code === 'LawEnforcementAndJudicial'){
          return item.list.slice(0,5)
        }
        return item ? item.list : [];
      } else {
        return [];
      }
    },
  },
};
</script>
