<template>
  <div class="footer">
    <div class="footer-menu">
      <span @click="$router.push('/')">首页</span>
      <span
        v-for="item in moduleMenuList"
        :key="item.id"
        @click="goModuleList(item.typelist[0])"
        >{{ item.module_name }}</span
      >
    </div>
    <div class="copyright-box">
      <p>Copyright &copy; {{ copyright }} {{ company }} 版权所有</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank">{{ icpcode }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["company", "copyright", "icpcode"]),
  },
  mounted() {},
  methods: {
    // changeMoudule(item) {
    //   console.log(item);
    // },
    goModuleList(item) {
      let name = "";

      if (item.page_ivtype === 2) {
        // 列表
        if (item.module_code === "PoliciesAndRegulations") {
          name = "ModuleOne";
        } else if (item.module_code === "LawEnforcementAndJudicial") {
          name = "ModuleTwo";
        } else if (item.module_code === "CrimePrevention") {
          if (item.page_customized === "DiscussTheIndustry") {
            name = "ModuleFive";
          } else {
            name = "ModuleFour";
          }
        } else if (item.module_code === "LawEnforcementTools") {
          name = "ModuleThree";
        }
      } else {
        // 单页
        name = "SinglePage";
      }

      if (item.page_customized === "FeedbackInteraction") {
        // 表单
        name = "FormOne";
      }

      this.$router.push({
        name,
        query: { module_code: item.module_code, id: item.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$mainColor: #0981e4;

@media only screen and (min-width: 769px) {
  .footer {
    height: 170px;
    padding-top: 30px;
  }
  .copyright-box {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    height: 70px;
    padding: 10px 0;
  }
  .footer-menu {
    display: none;
  }

  .copyright-box {
    font-size: 12px;
  }
}

.footer {
  background: $mainColor;
  color: #fff;

  .footer-menu {
    margin-bottom: 30px;
    text-align: center;

    span {
      margin: 0 14px;
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        content: "";
        left: -14px;
        top: 0;
        height: 100%;
        border-left: 1px solid #fff;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }

  .copyright-box {
    text-align: center;
    p {
      line-height: 1.5;
    }
  }
}
</style>