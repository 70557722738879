<template>
  <el-carousel class="carousel">
    <el-carousel-item v-for="(item, index) in list" :key="index">
      <div class="carousel-item">
        <template v-if="item.banner_img">
          <img :src="$config.baseUrl + item.banner_img.file_path" alt="" />
        </template>
        <template v-else>
          <img src="" alt="" />
        </template>
        <div class="carousel-item-title">{{ item.banner_title }}</div>
        <div class="carousel-item-btns">
          <span @click="toModule('PoliciesAndRegulations')">政策法规查询</span>
          <span @click="toModule('LawEnforcementTools')">能力建设课程</span>
          <span @click="toModule('ContactUs')">预约执法培训</span>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import { getBannerList } from "@/api/base.js";
export default {
  name: "Carousel",
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getBannerList()
        .then((res) => {
          const { data } = res;
          this.list = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toModule(moduleCode) {
      let menuItem = this.moduleMenuList.find(
        (el) => el.module_code === moduleCode
      );
      if (menuItem && menuItem.typelist.length > 0) {
        let name = this.matchName(menuItem.typelist[0]);
        this.$router.push({
          name,
          query: {
            module_code: menuItem.typelist[0].module_code,
            id: menuItem.typelist[0].id,
          },
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@media screen and (max-width: 768px) {
  .carousel {
    height: 256px;
    /deep/ .el-carousel__container {
      height: 100%;

      .el-carousel__arrow > i {
        font-size: 14px;
      }
    }

    .carousel-item {
      width: 100%;
      height: 100%;
      position: relative;
      /deep/ img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .carousel-item-title {
        width: 100%;
        padding: 0 10%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
      }

      .carousel-item-btns {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 16%;
        span {
          color: #fff;
          background: #1b92ee;
          font-size: 12px;
          margin-right: 4%;
          padding: 2%;
          border-radius: 4px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .carousel {
    height: 670px;
    /deep/ .el-carousel__container {
      height: 100%;

      .el-carousel__arrow > i {
        font-size: 24px;
      }
    }

    .carousel-item {
      width: 100%;
      height: 100%;
      position: relative;
      /deep/ img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .carousel-item-title {
        width: 100%;
        padding: 0 20%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #fff;
        font-size: 20px;
        line-height: 2;
      }

      .carousel-item-btns {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 16%;
        span {
          color: #fff;
          background: #1b92ee;
          font-size: 20px;
          margin-right: 10%;
          padding: 16px 36px;
          border-radius: 4px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>