import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index.js";
import * as config from "../config/index.js";

import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/module",
    name: "Module",
    component: () => import("@/views/modules/index.vue"),
    meta: {
      title: "模块",
    },
    children: [
      {
        path: "/moduleOne",
        name: "ModuleOne",
        component: () => import("@/views/modules/ModuleOne.vue"),
        meta: {
          title: "模块一",
          isModule: true,
        },
      },
      {
        path: "/moduleTwo",
        fullPath: "/module/moduleTwo",
        name: "ModuleTwo",
        component: () => import("@/views/modules/ModuleTwo.vue"),
        meta: {
          title: "模块二",
          isModule: true,
        },
      },
      {
        path: "/moduleThree",
        name: "ModuleThree",
        component: () => import("@/views/modules/ModuleThree.vue"),
        meta: {
          title: "模块三",
          isModule: true,
        },
      },
      {
        path: "/moduleFour",
        name: "ModuleFour",
        component: () => import("@/views/modules/ModuleFour.vue"),
        meta: {
          title: "模块四",
          isModule: true,
        },
      },
      {
        path: "/moduleFive",
        name: "ModuleFive",
        component: () => import("@/views/modules/ModuleFive.vue"),
        meta: {
          title: "模块五",
          isModule: true,
        },
      },
      {
        path: "/singlePage",
        name: "SinglePage",
        component: () => import("@/views/modules/SinglePage.vue"),
        meta: {
          title: "单页面",
          isModule: true,
        },
      },
      {
        path: "/formOne",
        name: "FormOne",
        component: () => import("@/views/modules/FormOne.vue"),
        meta: {
          title: "表格一",
          isModule: true,
        },
      },
      {
        path: "/formTwo",
        name: "FormTwo",
        component: () => import("@/views/modules/FormTwo.vue"),
        meta: {
          title: "表格二",
          isModule: true,
        },
      },
      {
        path: "/detailOne",
        name: "DetailOne",
        component: () => import("@/views/modules/DetailOne.vue"),
        meta: {
          title: "详情一",
          isModule: true,
        },
      },
      {
        path: "/detailTwo",
        name: "DetailTwo",
        component: () => import("@/views/modules/DetailTwo.vue"),
        meta: {
          title: "详情二",
          isModule: true,
        },
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      title: "注册",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/Search.vue"),
    meta: {
      title: "搜索",
    },
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Error.vue"),
    meta: {
      title: "未找到页面",
    },
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  let name = "";
  if (to.meta.isModule) {
    name = store.state.moduleName;
  } else {
    name = to.meta.title;
  }
  document.title = name ? name + "-" + config.title : config.title;

  next();
});

export default router;
