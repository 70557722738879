<template>
  <div class="title">
    <div class="zhtext" :style="{ color: textColor }">
      {{ zhText }}
    </div>
    <div class="cntext">
      <div class="line-left" :style="{ background: lineColor }"></div>
      <div class="line-right" :style="{ background: lineColor }"></div>
      <span class="s1" :style="{ color: textColor, background: bgColor }">{{
        cnText
      }}</span>
      <span
        class="s2"
        :style="{ color: btnColor, background: lineColor }"
        @click="toMore"
        >查看全部 >></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    textColor: {
      type: String,
      default: "#F1F1F1",
    },
    bgColor: {
      type: String,
      default: "#1B92EE",
    },
    lineColor: {
      type: String,
      default: "#ffffff",
    },
    btnColor: {
      type: String,
      default: "#007cf9",
    },
    zhText: {
      type: String,
      default: "",
    },
    cnText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    toMore() {
      this.$emit("toMore");
    },
  },
};
</script>

<style lang="scss" scoped>
// 主页每一项的title
@media only screen and (min-width: 769px) {
  .title {
    padding-top: 50px;
    margin-bottom: 2%;

    .zhtext {
      font-size: 18px;
      font-family: SourceHanSansCN;
      color: #000000;
      text-align: center;
      font-size: 16px;
    }

    .cntext {
      height: 48px;
      font-family: SourceHanSansCN;
      color: #000000;
      position: relative;
      font-size: 20px;

      .line-left {
        width: 50%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .line-right {
        width: 50%;
        height: 1px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .s1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 36px;
      }

      .s2 {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        font-size: 12px;
        padding: 4px 14px;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .title {
    padding-top: 20px;
    margin-bottom: 2%;

    .zhtext {
      font-size: 18px;
      font-family: SourceHanSansCN;
      color: #000000;
      text-align: center;
      font-size: 14px;
    }

    .cntext {
      height: 30px;
      font-family: SourceHanSansCN;
      color: #000000;
      position: relative;
      font-size: 14px;

      .line-left {
        width: 50%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .line-right {
        width: 50%;
        height: 1px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .s1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 20px;
      }

      .s2 {
        display: none;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        font-size: 12px;
        padding: 4px 14px;
        cursor: pointer;
      }
    }
  }
}
</style>