module.exports = {
    title: '野生动物犯罪观察',
    /**
    * @description api请求基础路径
    */
    // baseUrl: process.env.NODE_ENV === "development" ? 'http://demo.ymutech.com:802' : '', // 测试服
    baseUrl: process.env.NODE_ENV === "development" ? 'https://protectwildlife.cn' : '', // 线上
    // baseUrl: '',
    /**
    * @description 默认打开的首页的路由name值，默认为home
    */
    homeName: 'index',
}
