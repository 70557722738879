import { mapGetters } from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['moduleMenuList', 'moduleName', 'userId']),
        baseUrl() {
            return this.$config.baseUrl;
        },
    },
    methods: {
        // goModuleList(item) {
        //     console.log(item)
        // },
        matchName(item) {
            let name = "";

            if (item.page_ivtype === 2) {
                // 列表
                if (item.module_code === "PoliciesAndRegulations") {
                    name = "ModuleOne";
                } else if (item.module_code === "LawEnforcementAndJudicial") {
                    name = "ModuleTwo";
                } else if (item.module_code === "CrimePrevention") {
                    if (item.page_customized === "DiscussTheIndustry") {
                        name = "ModuleFive";
                    } else {
                        name = "ModuleFour";
                    }
                } else if (item.module_code === "LawEnforcementTools") {
                    name = "ModuleThree";
                }
            } else {
                // 单页
                name = "SinglePage";
            }

            if (item.page_customized === "FeedbackInteraction") {
                // 表单
                name = "FormOne";
            }
            return name
        }
    }

}